.individual-words-hero-image-container {
    max-width: 700px;
    padding-bottom: 50px;
}

.individual-words-hero-image-horizontal {
    width: 100%;
    max-height: 600px;
}

.individual-words-hero-image-vertical {
    width: auto;
    max-height: 600px;
}

.individual-words-hero-image-container-three {
    display: inline;
    float: none;
}

.individual-words-image-vertical-three {
    height: 100%;
    padding-right: 15px;
}

.individual-words-image-vertical-three:last-child {
    padding-right: 0px;
}


.individual-words-title {
    text-transform: uppercase;
    padding-bottom: 14px;
}

.individual-words-paragraph-container {
    max-width: 700px;
}

.individual-words-paragraph {
    line-height: 1.5;
    margin: 15px 0;
}    

.individual-words-text-container {
    padding-top: 15px;
}

.individual-words-closing-text {
    font-style: italic;
    font-size: 16px;
}


.individual-words-image-horizontal {
    width: 100%;
    max-height: 500px;
    padding: 35px 0;
}

.individual-words-image-vertical {
    max-height: 500px;
    padding: 35px 15px 35px 0px;
}

// mobile only
@media screen and (max-width: 769px) {
    .individual-words-image-horizontal, .individual-words-image-vertical {
        width: 100%;
        max-height: 500px;
        padding: 35px 0;
    }

    .individual-words-image-vertical-two {
        width: 45%;
        padding: 35px 20px 35px 0px;
    }

    .individual-words-image-vertical-two:last-child {
        padding-right: 0px;
    }
}

// desktop only
@media screen and (min-width: 769px) {
    .individual-words-image-horizontal {
        width: 100%;
        max-height: 500px;
        padding: 35px 0;
    }

    .individual-words-image-vertical {
        max-height: 500px;
        padding: 35px 15px 35px 0px;
    }

    .individual-words-image-vertical-two {
        width: 45%;
        max-height: 600px;
        padding: 35px 20px 35px 0px;
    }

    .individual-words-image-vertical-two:last-child {
        padding-right: 0px;
    }
}




