.exhibition-image {
    max-height: 600px;
    padding-bottom: 50px;
}

.exhibition-section-type {
    padding-bottom: 40px;
}

.exhibition-type-title {
    font-size: 20px;
    padding-bottom: 25px;
}

.exhibition-year-group {
    display: flex;
    flex-direction: row;
}

.exhibition-container {
    padding-bottom: 25px;
    padding-left: 15px;
}

.exhibition-year {
    padding-bottom: 15px;
    font-size: 16px;
    width: 85px;
}

.exhibition-name {
    font-size: 16px;
}

.exhibition-gallery-name {
    font-size: 16px;
}

.exhibition-dates {
    font-size: 16px;
}

.exhibition-link {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}

@media screen and (max-width: 500px) {
    .exhibition-container {
        max-width: 241px;
    }
}

@media screen and (max-width: 768px) {
    .exhibition-image {
        width: 89vw;
        height: auto;
    }
}