.image-container {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .home-image {
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    .home-image {
        width: 45%;
    }
}