.biography-image {
    height: 600px;
    padding-bottom: 50px;
}

.biography-section-type {
    padding-bottom: 40px;
}

.biography-type-title {
    font-size: 20px;
    padding-bottom: 25px;
}

.biography-year-group {
    display: flex;
    flex-direction: row;
}

.biography-container {
    padding-bottom: 25px;
}

.biography-year {
    padding-bottom: 15px;
    padding-right: 15px;
    font-size: 16px;
    width: 85px;
}

.biography-content-right {
    width: 75%;
}

.biography-container p {
    font-size: 16px;
    line-height: 1.2;
}

.biography-link {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}

.notFound-image {
    max-height: 340px;
    max-width: 100%;
    padding-bottom: 50px;
}


@media screen and (max-width: 500px) {
    .biography-container {
        max-width: 241px;
    }
}

@media screen and (max-width: 768px) {
    .biography-image {
        width: 89vw;
        height: auto;
    }
}