@font-face {
    font-family: Trebuchet;
    src: url('/trebucbd.ttf');
}

h1 {
    font-size: 31px;
    line-height: inherit;
}

h2 {
    font-size: 22px;
    line-height: inherit;
}

h3 {
    font-size: 18px;
    line-height: inherit;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 15px;
    line-height: inherit;
}

p {
    font-size: 14px;
    padding: 2px 0;
}

@media screen and (max-width: 600px) {
    p {
        padding: 1px 0;
    }
}

b {
    font-weight: 700;
    line-height: inherit;
}

.uppercase {
    text-transform: uppercase;
}

.italics {
    font-style: italic;
}