.individual-image-content-container {
    margin: 0 auto;
    display: inline-block;
}

.individual-image-details {
    color: #999;
    padding-top: 10px;
}

.thumbnail-section {
    display: flex;
}

@media screen and (max-width: 768px) {
    .individual-image-main {
        width: 100%;
    }
} 

@media screen and (min-width: 574px) {
    .individual-image-main {
        max-height: 75vh; 
        max-width: 100%;
        width: auto;
    }    
}

@media screen and (min-width: 769px) {
    .individual-image-details {
        color: #999;
        display: flex;
        justify-content: space-between;
    }
}

.thumbnail-image {
    height: 82px;
    margin: 0 0 0 7px;
    cursor: pointer;
}

.current-image-border {
    border-width: 0px 0px 3px 0px;
    border-style: solid;
    border-color: #fff;
}

.non-current-image-border {
    border: 0;
}

