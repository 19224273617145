.gallery-name {
    font-size: 20px;
    padding-bottom: 0px;
}

.gallery-website {
    padding-top: 10px;
    text-decoration: underline;
}

.contact-first-row, .contact-second-row {
    display: flex;
    flex-direction: row;
}

.gallery-information-container {
    padding-bottom: 38px;
}

.contact-address-section {
    padding-bottom: 20px;
    padding-top: 20px;
}

.contact-address-container {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}

.contact-type-icon {
    width: 32px;
}

.contact-address-text {
    padding-left: 15px;
}

.legal-text {
    padding-top: 20px;
}